<template>
    <Dialog
        v-model:visible="visivel"
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '70vw'}"
        :maximizable="true"
        :modal="true"
        @show="iniciar()"
        @hide="$emit('fechar')"
    >
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-book mr-1" style="font-size: 1.4rem"></i>
                <h3 v-if="!ajusteRe" style="margin:0px 5px;">Movimentos RE</h3>
                <h3 v-if="ajusteRe" style="margin:0px 5px;">Manutenção RE</h3>
            </div>
        </template>

        
        <div class="grid" v-if="!ajusteRe">
            <div class="col-12">
                <DataTable responsiveLayout="stack" showGridlines :value="movimentos">
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>

                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>

                    <Column field="codEstabel" header="Estabelecimento" style="width:4rem;" class="pb-3 pt-3"/>
                    <Column field="idRe" header="Id Re" style="width:4rem;" />
                    <Column field="nrRe" header="Número Re" style="width:4rem;" />
                    <Column field="dataHoraMovimento" header="Data Hora Movimento" style="width:4rem;">
                        <template #body="{ data }">
                            {{ formatDate(data.dataHoraMovimento, 'DD/MM/YYYY HH:mm:ss') }}
                        </template>
                    </Column>
                    <Column field="descricaoMovimento" header="Movimento" style="width:4rem;" />
                    <Column field="descricaoStatusMovimento" header="Status do Movimento" style="width:4rem;" />
                    <Column field="mensagem" header="Mensagem" style="width:6rem;" />
                    <Column header="Ações" style="width:7rem;" class="centralizar-titulo-tabela">
                        <template #body="{ data }">
                            <Button v-if="(data.movimento !== 'CRIACAO_RE' && data.movimento !== 'RETORNO_NF_REMESSA' && data.movimento !== 'ABERTO') || movimentos.length == 1"  
                                class="p-button-rounded p-button-warning flex-none mr-2" 
                                icon="pi pi-send" 
                                title="Reenviar" 
                                @click="reenviar(data.id)"></Button>

                            
                            <Button v-if="ativarBotaoManutencao && data.statusMovimento === 'FALHA'"
                                icon="pi pi-cog"
                                title="Manutenção RE" 
                                class="p-button-rounded p-button-success mr-2"
                                @click="carregarFormAjusteRe(data.idRe)">
                            </Button>

                            <Button v-if="ativarBotaoManutencao && data.statusMovimento === 'FALHA'"
                                icon="pi pi-file-export"
                                title="Gerar Nova NF" 
                                class="p-button-rounded p-button-info"
                                @click="gerarNovaNotaFiscal(data.codEstabel, data.nrRe)">
                            </Button>
                        </template>
                    </Column>
                    
                </DataTable>
            </div>
        </div>
            
        <AjusteReForm v-if="ajusteRe" :idRecEntrega="idRecEntrega" @voltar="cancelarAjusteRe" />
        
        <template #footer v-if="!ajusteRe">
            <Paginator
                :rows="qtdRegistro"
                :totalRecords="totalRegistro"
                :rowsPerPageOptions="[3, 5, 10, 20, 30]"
                @page="onPage"
            ></Paginator>
        </template>
    </Dialog>
</template>

<script>
import NfRemessaGerarNotaFiscalService from '../../service/NfRemessaGerarNotaFiscalService'
import TratamentoTransacaoReService from '../../service/TratamentoTransacaoReService';
import RecEntregaAjusteReService from '../../service/RecEntregaAjusteReService';
import Formatacao from '../../utilities/Formatacao';
import AjusteReForm from './AjusteReForm.vue';
    
export default {
    emits: ['fechar'],
    components: { AjusteReForm },
    props: {
        idRe: {
            type: String,
            required: false
        },
        visivel: {
            type: Boolean,
            required: true
        },
        codigoEstabelecimento: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            totalRegistro: 0,
            qtdRegistro: 10,
            pagina: 0,
            firstRow: 0,
            estabelecimento: null,
            movimentos:[],

            ativarBotaoManutencao: false,
            ajusteRe: false,
            idRecEntrega: null,
        }
    },
    methods: {
        iniciar() {
            this.ajusteRe = false;
            this.idRecEntrega = null;
            this.carregarMovimentos();

            RecEntregaAjusteReService.verificarSeUsuarioPossuiPermissaoRe(this.codigoEstabelecimento)
                .then(() => { this.ativarBotaoManutencao = true; })
                .catch(() => { this.ativarBotaoManutencao = false; });
        },

        carregarMovimentos() {
            TratamentoTransacaoReService.getMovimentos(this.idRe, this.pagina, this.qtdRegistro, this.filtros, this.ordenar)
                .then(({ data }) => {
                        if(data) {
                            this.movimentos = data.content;
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalPages * this.qtdRegistro;
                        }
                })
                .catch(error => {
                        this.movimentos = [];
                        this.qtdRegistro = 0;
                        this.totalRegistro = 0;
                    });
        },

        onPage(event) {
            this.pagina = event.page;
            this.qtdRegistro = event.rows;
            this.carregarHistorico();
        },

        formatDate(data, format) {
            return Formatacao.formatDateCustom(data, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        reenviar(id){
            TratamentoTransacaoReService.reenviar(id)
                .then( ({ data }) => {
                    this.$toast.add({
                        severity:'success', 
                        detail:'Movimento reenviado com sucesso!', 
                        life: 3000
                    })
                })
                .catch(error => {
                    if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;
                        const mensagem = message == undefined || message == null || message == "" ? exception + ""  : message + "";
                        const mensagens = mensagem.split("\n");

                        for(let mensagemAux of mensagens) {
                            this.$toast.add({
                                severity:'error', 
                                summary: 'Falha ao reenviar o movimento',
                                detail: mensagemAux, 
                                life: 15000
                            });
                        }
                    }
                    else {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Falha não mapeada ao reenviar o movimento!',
                            detail: error, 
                            life: 15000
                        });
                    }
                })
        },

        gerarNovaNotaFiscal(codEstabel, nrRe) {
            NfRemessaGerarNotaFiscalService.gerarNovaNotaFiscal(codEstabel, nrRe)
                .then(response => {
                    this.$toast.add({
                        severity:'success',
                        summary: 'Solicitando nova nota fiscal',
                        detail:`Solicitando nova nota fiscal para RE ${nrRe}.`, 
                        life: 7500
                    });
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Não foi possível solicitar nova nota fiscal!',
                        detail: "Falha não mapeada", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        carregarFormAjusteRe(idRecEntrega) {
            this.ajusteRe = true;
            this.idRecEntrega = idRecEntrega;
        },

        cancelarAjusteRe() {
            this.carregarMovimentos();
            this.ajusteRe = false;
            this.idRecEntrega = null;
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
