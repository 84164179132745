import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class NfRemessaGerarNotaFiscalService {
    gerarNovaNotaFiscal(codEstabel, nrRe) {
        const config = {
            headers: { 'content-type': 'application/json' }
        }
        return axios.put(`${ ApiUrl.portal_url }api/pa/v1/gerar-nota-fiscal/nova-nota-fiscal/codigo-estabelecimento/${ codEstabel }/numero-re/${ nrRe }`, config);
    }
}
export default new NfRemessaGerarNotaFiscalService();