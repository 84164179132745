<template>
    <div class="grid formgrid grid">
        <div class="col-12 mb-3">
            <div class="flex flex-wrap justify-content-center gap-3">
                <div class="flex align-items-center mr-5">
                    <label for="alterarImovel" class="mr-2">Escolha o tipo da manutenção: </label>
                    <RadioButton v-model="tipo" id="alterarImovel" value="ALTERAR_IMOVEL" @click="alterarImovelAction()" :disabled="bloquearFuncoes" :class="{'p-invalid': tipoIsInvalid}" />
                    <label for="alterarImovel" class="ml-1"> Alterar Imóvel</label>
                </div>

                <div class="flex align-items-center">
                    <RadioButton v-model="tipo" id="nomeCooperativa" value="NOME_COOPERATIVA" @click="nomeCooperativaAction()" :disabled="bloquearFuncoes" :class="{'p-invalid': tipoIsInvalid}" />
                    <label for="nomeCooperativa" class="ml-1">Nome Cooperativa</label>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="field">
                <label>
                    <strong>Estabelecimento</strong>
                </label>
                <div class="flex w-full">
                    <InputText v-model="dados.estabelecimento.codigo" style="width: 25%;" class="mr-1" type="text" :disabled="true" />
                    <InputText v-model="dados.estabelecimento.nomeFantasia" style="width: 75%;" type="text" :disabled="true" />
                </div>
            </div>
        </div>
        
        <div class="col-2">
            <div class="field">
                <label>
                    <strong>Doc. Pesagem</strong>
                </label>
                <InputText v-model="dados.documentoPesagem" class="w-full" type="text" :disabled="true" />
            </div>
        </div>

        <div class="col-4">
            <div class="field">
                <label>
                    <strong>Grupo de Produto</strong>
                </label>
                <div class="flex w-full">
                    <InputText v-model="dados.grupoProduto.codigo" style="width: 25%;" class="mr-1" type="text" :disabled="true" />
                    <InputText v-model="dados.grupoProduto.descricao" style="width: 75%;" type="text" :disabled="true" />
                </div>
            </div>
        </div>
        
        <div class="col-6">
            <div class="field">
                <label>
                    <strong>Produtor</strong>
                </label>
                <div class="flex w-full">
                    <InputText v-model="dados.produtor.codigo" style="width: 25%;" class="mr-1" type="text" :disabled="true" />
                    <InputText v-model="dados.produtor.nome" style="width: 75%;" type="text" :disabled="true" />
                </div>
            </div>
        </div>
        
        <div class="col-6">
            <div class="field">
                <label>
                    <strong>Imóvel</strong>
                </label>
                <AutoComplete
                    class="w-full"
                    field="matriculaNome"
                    v-model="dados.imovel"
                    @complete="buscarImoveis($event)"
                    :dropdown="true"
                    :forceSelection="true"
                    :suggestions="imoveisFiltro"
                    :disabled="tipo != 'ALTERAR_IMOVEL'"
                    :class="{'p-invalid': imovelIsInvalid}"
                /> 
            </div>
        </div>
        
        <div class="col-6" v-if="(dados.nomeProdutor !== undefined && dados.nomeProdutor !== null) || tipo == 'NOME_COOPERATIVA'">
            <div class="field">
                <label>
                    <strong>Nome Produtor</strong>
                </label>
                <InputText v-model="dados.nomeProdutor" class="w-full" type="text" :disabled="true" />
            </div>
        </div>
    </div>

    <div class="flex justify-content-center">
        <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-info mr-2" @click="salvar()"></Button>
        <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger"  @click="$emit('voltar')"></Button>
    </div>
</template>

<script>
import RecEntregaAjusteReService from '../../service/RecEntregaAjusteReService';
import ImoveisService from '../../service/ImoveisService';
import Formatacao from '../../utilities/Formatacao';

export default {
    emits: ['voltar'],

    props: {
        idRecEntrega: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            dados: {
                estabelecimento: { },
                grupoProduto: { },
                produtor: { },
                documentoPesagem: null,
                imovel: null
            },
            tipo: null,
            dadosRe: null,
            imoveisFiltro: [],
            bloquearFuncoes: false,

            imovelIsInvalid: false,
            tipoIsInvalid: false
        }
    },

    created() { 
        this.buscarDadosRe();
    },

    methods: {
        buscarDadosRe() {
            RecEntregaAjusteReService.buscarRecEntregaPorId(this.idRecEntrega)
                .then(({ data }) => {
                    if(data) {
                        this.dadosRe = Object.assign({}, data);
                        this.dados = data;

                        if(data.nomeProdutor !== undefined && data.nomeProdutor !== null) {
                            this.bloquearFuncoes = true;
                            this.tipo = "NOME_COOPERATIVA";
                        }
                        
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Não foi possível buscar dados da RE!',
                        detail: "Falha não mapeada", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        buscarImoveis(event) {
            const estado = this.dados.estabelecimento.estado;
            const codProdutor = this.dados.produtor.codigo;
            const matriculaOuDescricao = !event.query.trim().length ? undefined : event.query;
            
            ImoveisService.pesquisarPorCodigoProdutorEMatriculaOuDescricaoSemSerTransferencia(codProdutor, estado, matriculaOuDescricao)
                .then(({ data }) => {
                    if(!!data && data.length > 0) {
                        this.imoveisFiltro = data;
                    }
                    else {
                        this.imoveisFiltro = [];
                        
                        if(!matriculaOuDescricao) {
                            this.$toast.add({
                                severity:'warn',
                                summary: 'Atenção',
                                detail:`O produtor "${ this.dados.produtor.nome }" não possui nenhum imóvel registrado no estado ${ Formatacao.obterNomeEstado(this.estabelecimento.estado, true) }.`,
                                life: 7500
                            });
                        }
                        else {
                            this.$toast.add({
                                severity:'warn',
                                summary: 'Atenção',
                                detail:`Não foi encontrado nenhum imóvel registrado no estado ${ Formatacao.obterNomeEstado(this.estabelecimento.estado, true) } com a matrícula ou descrição "${ matriculaOuDescricao }".`,
                                life: 7500
                            });
                        }
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Não foi possível buscar imóveis!',
                        detail: "Falha não mapeada", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        nomeCooperativaAction() {
            RecEntregaAjusteReService.buscarDadosNomeCooperativa(this.dados.estabelecimento.codigo)
                .then(({ data }) => {
                    if(data) {
                        this.dados["nomeProdutor"] = this.dados.produtor.nome;
                        this.dados["produtor"] = data.produtor;
                        this.dados["imovel"] = data.imovel;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Não foi possível buscar dados da cooperativa',
                        detail: "Falha não mapeada", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        alterarImovelAction() {
            this.dados["nomeProdutor"] = undefined;
            this.dados["produtor"] = Object.assign({}, this.dadosRe.produtor);

            if(!!this.dadosRe.imovel) {
                this.dados["imovel"] = Object.assign({}, this.dadosRe.imovel);
            }
            else {
                this.dados["imovel"] = null;
            }
        },

        validarForm() {
            let campos = [];

            if(this.tipo === null) {
                campos.push("Tipo");
                this.tipoIsInvalid = true;
            }
            else {
                this.tipoIsInvalid = false;
            }

            if(this.tipo === "ALTERAR_IMOVEL" && this.dados.imovel === null) {
                campos.push("Imóvel");
                this.imovelIsInvalid = true;
            }
            else {
                this.imovelIsInvalid = false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Falha ao ajustar RE!',
                    detail: mensagem, 
                    life: 7500
                });
            }

            return campos.length === 0;
        },

        salvar() {
            if(!this.validarForm()) return;

            var obj = {};
            obj["idRecEntrega"] = this.idRecEntrega;
            obj["matriculaImovel"] = this.dados.imovel.matricula;
            obj["tipo"] = this.tipo;

            RecEntregaAjusteReService.salvar(obj)
                .then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Manutenção RE",
                        detail: "A manutenção da RE foi salvo com sucesso!", 
                        life: 7500
                    });

                    this.$toast.add({
                        severity: "warn",
                        summary: "Iniciando sincronização!",
                        detail: "As informações da RE estão sendo encaminhado para o Datasul.", 
                        life: 7500
                    });

                    this.$emit("voltar");
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Não foi possível salvar!',
                        detail: "Falha não mapeada", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>