import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class RecEntregaAjusteReService {

    buscarRecEntregaPorId(idRecEntrega) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/recentrega/ajuste-re/buscar-por/id-recentrega/${ idRecEntrega }`);
    }

    buscarDadosNomeCooperativa(codigoEstabelecimento) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/recentrega/ajuste-re/nome-cooperativa/buscar-por/codigo-estabelecimento/${ codigoEstabelecimento }`);
    }

    verificarSeUsuarioPossuiPermissaoRe(codigoEstabelecimento) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/recentrega/ajuste-re/verificar-se-usuario-possui-permissao-re/codigo-estabelecimetno/${ codigoEstabelecimento }`);
    }

    salvar(data) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.put(`${ ApiUrl.portal_url }api/pa/v1/recentrega/ajuste-re`, data, config);
    }
}

export default new RecEntregaAjusteReService();