import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class TratamentoTransacaoReService {

    buscarPorPaginacao(params = {} ) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/`, { params });
    }

    getMovimentos(idRe, pagina, qtdRegistro, ordenar){
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/movimentos/${ idRe }`, {
            params: {
                page: pagina,
                size: qtdRegistro,
                //sort: ordenar,
            }
        });
    }

    reenviar(idTransacaReMov){
        return axios.post(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/reenviar/?id=${ idTransacaReMov }`);
    }

    listarTipoMovimentoRe() {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/tipos`);
    }

    listarStatusMovimentoRe() {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/status`);
    }

    informacoes() {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/tratamento-transacao-re/informacoes`);
    }
}

export default new TratamentoTransacaoReService();
